<template>
  <div class="digital-files">
    <menu-card></menu-card>
  </div>
</template>

<script>
import MenuCard from '@/components/DigitalCell/TreeSelectDA'
export default {
  name: "DigitalDangAn",
  components: {MenuCard},
}
</script>

<style scoped>

.digital-files{

}

</style>